import Navbar from "../../components/Navbar";
import {Link} from 'react-router-dom'
import { VscCheck } from "react-icons/vsc";
import CarouselEnglishOnline from "../../components/CarouselEnglishOnline";
import Guy from '../../img/EnglishOnline/GuyPointingSmiling.webp'
import Girl from '../../img/EnglishOnline/GirlOnMeeting.webp'


export const EnglishOnline = ()=> {
  let links = [
    { link: "/", name: "Home" },
    { link: "/Contact", name: "Contactar" },
  ];
  return (
    <div className="select-none">
      <div className="bg-gradient-to-r from-blue-800 to-sky-600">
        <Navbar>
        {links.map((item, index) => {
          return (
            <Link key={index} to={item.link} about={item.name}>
              <span className="m-3 p-2 rounded-md md:text-white font-lato md:text-xl">
                {item.name}
              </span>
            </Link>
          );
        })}
      </Navbar>
      <div className="md:flex md:items-center">
        <div className="md:pl-4">
          <h1 className="font-oswald font-bold  text-5xl text-white text-center">English Online</h1>
          <p className="font-lato text-lg text-center text-yellow-300 my-2 md:my-16">Aprende Inglés con nuestro curso <strong className="text-amber-600">100% Online.</strong> Toma las clases desde ¡Cualquier lugar!</p>
          <p className="font-lato text-center text-white text-lg mb-2">Para más información:</p>
          <p className="px-2 text-center font-lato text-lg text-white">✆<a href="https://wa.me/525568742266?text=Me%20interesa%20saber%20más%20de%20las%20clases" className="underline">+52 55 6874 2266</a></p>
        </div>
        <img src={Guy} alt="Guy smiling" />
      </div>
      </div>

      <div className="bg-gray-100 md:flex md:justify-around">
        <div className="p-3 md:mx-4">
          <img src={Girl} alt="Gir on a meeting" className="shadow-md rounded-md"/>
        </div>
        <div className="md:flex md:flex-col md:items-center md:py-4">
          <p className="text-red-500 font-lato text-lg text-center">Curso 100% Online</p>
          <p className="font-lato text-xl text-base text-center md:my-4">PROFESORES CAPACITADOS</p>
          <ul className="px-2">
            <li className="flex items-center"><VscCheck className="text-red-800"/> Horarios flexibles.</li>
            <li className="flex items-center"><VscCheck className="text-red-800"/> Aprende desde cualquier lugar.</li>
            <li className="flex items-center"><VscCheck className="text-red-800"/> Clases a toda América y España.</li>
            <li className="flex items-center"><VscCheck className="text-red-800"/> Profesores nativos con experiencia en el método Online.</li>
          </ul>
        </div>
      </div>
      <div className="p-2">
        <p className="font-lato text-xl text-base text-center ">Comodidad, espacio y tiempo</p>
        <p className="font-lato text-center">Las clases pueden ser particulares, en pareja o en grupos pequeños, a través de la plataforma que el alumno desee, Skype, Zoom, Teams, Otros. </p>
      </div>
      <div className="bg-gradient-to-b from-orange-500 to-red-500 text-center p-5">
        <h2 className="font-oswald text-white text-xl">Más de 4 años impartiendo clases online en <strong>TODA</strong> Latinoamérica</h2>
      </div>
      <div className="py-6 md:flex md:justify-center">
        <div className="md:w-1/2">
          <CarouselEnglishOnline/>
          <p className="font-lato text-xl text-blue-600 text-center">¡Contacta con algún profesor!</p>
        </div>
        
      </div>
      <div className="bg-base">
        <div className="border-b mx-2">
          <p className="text-white font-lato text-lg text-center py-2">Clases Online para empresas y ejecutivos.</p>
        </div>
        <div className="text-center py-3">
          <p className="text-white font-lato text-lg text-center">CLICK AQUÍ</p>
          <p className="text-white font-lato text-lg text-center">↓</p>
          <div className="text-white font-lato text-lg text-center ">
            <Link to={"/EnglishCourses/BusinessEnglish"}><span className="underline">Business English </span></Link>& 
            <Link to={"/EnglishCourses/FaceToFace"}><span className="underline"> Face to Face</span></Link> 
          </div>
          <Link to={"/Contact"}><span className="text-white font-lato text-lg text-center underline"> Para mas información</span></Link>
        </div>

      </div>
    </div>
  );
}
