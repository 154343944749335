import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

export default function Faq() {
const [question,setQuestion] = useState(null)
const [isOpen,setIsOpen] = useState(false)

function handleOnClick(e){
  let value = e.target.dataset.valor
  setIsOpen(!isOpen)
  setQuestion(value)
}

  return (
    <div className="flex flex-col items-center bg-fondo md:text-xl ">
      <h2 className="pt-4">
        <strong>FAQ</strong>
      </h2>
      <div className="border-b border-black w-full flex items-center flex-col"  >
        <section data-valor='1' className="w-full text-center  flex items-center justify-between flex-wrap px-2 my-3 " onClick={handleOnClick}>
          ¿Los cursos son para todo LATAM?
          {isOpen && question === '1' ? <BiChevronUp data-valor='1' onClick={handleOnClick} className='cursor-pointer'/>: <BiChevronDown data-valor='1' onClick={handleOnClick} className='cursor-pointer'/>} 
       </section>
        {isOpen && question === '1' && <p className="text-justify p-2"> Si, gracias a los curos 100% Online, tienes la posibilidad de tomar Clases de Inglés en donde sea que te encuentres, desde la comodidad de tu casa.</p>}
      </div>

      <div className="border-b border-black w-full flex items-center flex-col" >
        <section  data-valor='2' className="w-full text-center  flex items-center justify-between flex-wrap px-2 my-3" onClick={handleOnClick}>
          ¿Puedo personalizar mi curso?
          {isOpen && question === '2'? <BiChevronUp data-valor='2'  onClick={handleOnClick} className='cursor-pointer'/>: <BiChevronDown data-valor='2'  onClick={handleOnClick} className='cursor-pointer'/>}
        </section>
        {isOpen && question === '2' && <p className="text-justify p-2">Si, los paquetes y cursos son 100% personalizables. Se adaptan a tus necesidades, objetivos, metas, para tratar de alcanzar estos mismos en el tiempo deseado.</p>}
      </div>

      <div className="border-b border-black w-full flex items-center flex-col" >
        <section data-valor='3' className="w-full text-center  flex items-center justify-between flex-wrap px-2 my-3" onClick={handleOnClick}>
          ¿Las clases son particulares?
          {isOpen && question ==='3' ? <BiChevronUp data-valor='3'  onClick={handleOnClick} className='cursor-pointer'/>: <BiChevronDown data-valor='3'  onClick={handleOnClick} className='cursor-pointer'/>}
        </section>
        {isOpen && question === '3' && <p className="text-justify p-2">Las clases son particulares o en pareja. En el caso de empresas, las clases pueden ser particulares, en pareja y grupos pequeños.</p>}
      </div>

      <div className="border-b border-black w-full flex items-center flex-col" >
        <section data-valor='4' className="w-full text-center  flex items-center justify-between flex-wrap px-2 my-3" onClick={handleOnClick}>
          ¿Cuál es el precio de los cursos?
          {isOpen && question === '4'? <BiChevronUp data-valor='4'  onClick={handleOnClick} className='cursor-pointer'/>: <BiChevronDown data-valor='4'  onClick={handleOnClick} className='cursor-pointer'/>}
        </section>
        {isOpen && question === '4' && <p className="text-justify p-2">Los precios varian en cada paquete, ya que estos son personalizables, además de que contamos con promociones todo el tiempo que modifican el valor de tu curso. Esto para hacer los precios lo más accesible posible para nuestros clientes.</p>}
      </div>

      <div className="border-b border-black w-full flex items-center flex-col" >
        <section data-valor='5' className="w-full text-center  flex items-center justify-between flex-wrap px-2 my-3" onClick={handleOnClick}>
          ¿Con qué promociones cuenta ELC?
          {isOpen && question === '5'? <BiChevronUp data-valor='5'  onClick={handleOnClick} className='cursor-pointer'/>: <BiChevronDown data-valor='5'  onClick={handleOnClick} className='cursor-pointer'/>}
        </section>
        {isOpen && question === '5' && <p className="text-justify p-2">Las promociones varian todo el año, esto por fechas y ocasiones especiales, eventos y más. Las promociones pueden ser: Descuentos, Clases extra totalmente gratis, asesorias gratis, poliza de cancelaciones y más.  </p>}
      </div>
    </div>
  );
}
