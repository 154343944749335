import { Routes, Route } from "react-router-dom";
import {Contacto} from './pages/Contact'
import {BusinessEnglish} from './pages/EnglishCourses/BusinessEnglish'
import {EnglishOnline} from './pages/EnglishCourses/EnglishOnline'
import {FaceToFace} from './pages/EnglishCourses/FaceToFace'
import {EnglishCourses} from './pages/EnglishCourses/index'
import {Home} from './pages/Home'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Contact" element={<Contacto />} />
      <Route path="/EnglishCourses/BusinessEnglish" element={<BusinessEnglish />} />
      <Route path="/EnglishCourses/EnglishOnline" element={<EnglishOnline />} />
      <Route path="/EnglishCourses/FaceToFace" element={<FaceToFace />} />
      <Route path="/EnglishCourses" element={<EnglishCourses />} />
    </Routes>
  );
}

export default App;
