
export default function BannerEnglishCourses({image}){
    return (
        <section className="bg-white md:flex md:justify-center md:flex-col">
        <div className="flex flex-col items-center justify-center">
            <p className="text-base font-oswald text-center my-3 md:text-4xl">¡CONSULTA TODAS NUESTRAS MODALIDADES</p>
            <img src={image} alt='Banner'/>
        </div>
        <div className="my-3">
            <p className="text-base font-oswald font-bold text-center text-2xl pt-4 border-t-2 mx-3 md:text-2xl">Profesores Americanos</p>
        </div>
    </section>
    )
}