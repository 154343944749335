import FrontPage from '../../components/FrontPage'
import ContactPortada from '../../img/Contact/ContactPortada.webp'

export const Contacto = () => {

  return (
    <div className="select-none flex flex-col justify-between  fixed top-0 bottom-0 right-0 left-0">

        <div className="overflow-hidden ">
           <FrontPage
        image={ContactPortada}
        title='CONTACTAR'
        subTitle='Con un profesor Americano'
         />   
         </div>
      <div className="px-3 my-4 ">
        <div className=" ">
            <h2 className="font-oswald text-xl text-base text-center">Para más información acerca de las clases, precios, horarios, promociones, más.</h2>
            <ul className="font-lato text-lg text-center ">
                <li>Voice mail: <strong className="text-blue-600">+1 415 539-2434</strong></li>
                <li>Tel Spain: <strong className="text-blue-600">+34 600 56 04 22</strong></li>
                <li>Tel NL:<strong className="text-blue-600">+31 6 45332579</strong></li>
                <li>Tel Mex: <strong className="text-blue-600"><a className="underline" href="https://wa.me/525568742266?text=Me%20interesa%20saber%20más%20de%20las%20clases">+52 55 6874 2266</a></strong>(WhatsApp only)</li>
                <li> <strong className="text-blue-600">+52 55 3491 1169</strong></li>
                <li> <strong className="text-blue-600">+52 33 1912 6243</strong></li>
            </ul>
        </div>
    </div>

    <div className="text-white bg-base py-10 ">
            <p className="text-center text-xl">Horarios:  9 a.m. a 7 p.m. (Hora México, centro)</p>
    </div>

    </div>
  );
}
