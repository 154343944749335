import FrontPage from "../../components/FrontPage";
import CarouselBusinessEnglish from "../../components/CarouselBusinessEnglish";
import {BsWhatsapp} from "react-icons/bs"
import BusinessEnglishImg from '../../img/BusinessEnglish/BusinessEnglishPortada.webp'
import CoupleBusiness  from '../../img/BusinessEnglish/CoupleBusiness.webp'

export const BusinessEnglish =()=> {
  return (
    <div className="select-none">
      <div className="mb-4 overflow-hidden">
        <FrontPage
        image={BusinessEnglishImg}
        title={"Business English"}
        subTitle={"Expande tu empresa a áreas bilingües"}
      />
      </div>
      
      <div className="py-4 md:bg-white">
        <h2 className="text-center font-oswald text-xl text-blue-600 md:text-2xl">
          Clases <strong>100%</strong> Enfocadas al{" "}
          <strong className="underline">Ámbito Empresarial</strong>
        </h2>
        <p className="text-center font-lato text-xl my-2 px-2">
          Capacita a tu equipo con nuestros profesores con{" "}
          <strong>más de 10 años de experiencia</strong> impartiendo clases de{" "}
          <strong>inglés empresarial</strong> a distintas empresas en{" "}
          <strong>toda Latinoamérica.</strong>
        </p>
      </div>
      <div className="md:flex md:justify-center md:items-center md:bg-white">
        <div className="md:w-3/4">
           <ul className="px-2 text-lg">
        <li>■ Mejora la productividad y resultados de tu empresa</li>
        <li>■ Extiende tu empresa a USA, Canadá, Gran Bretaña y ¡Muchos otros lugares!</li>
        <li>■ Amplia los conocimientos de tu team </li>
        <li>■ Mejora el clima laboral</li>  
        </ul>
        <p className="text-center font-lato font-bold text-xl my-2 md:my-7">Te ayudamos a perfeccionar tu nivel de Inglés para tus:</p>
        <ul className="px-2 text-lg">
          <li>■ Entrevistas</li>
          <li>■ Presentaciones</li>
          <li>■ Exámenes</li>
          <li>■ Oportunidades de trabajo en otros lugares</li>
        </ul>
        </div>
        <div className="md:w-1/2">
          <CarouselBusinessEnglish/>
        </div>       
      </div>


      <p className="text-blue-600 font-lato text-lg py-4 px-2 md:text-center md:bg-white">Adquiere la posibilidad de obtener un <strong>mejor sueldo, mejor posición de trabajo, posibilidades de mudarse a otro país de habla inglesa, entre otros, incrementando tu nivel de Inglés.</strong></p>
      <div className="bg-base h-14"></div>
      <p className="text-xl text-center py-2 bg-white">¡Clases particulares,<strong className="text-blue-600">uno a uno!</strong></p>
      <div className="p-2 bg-gray-100 md:flex">
        <img src={CoupleBusiness} alt="Couple Business English" className="shadow-md rounded-md"/>
        <div className="md:flex md:flex-col md:items-center md:justify-evenly">
          <p className="font-lato text-lg text-center md:text-xl">Tener un profesor privado ¡Solo para ti!</p>
        <p className="font-lato text-lg text-center text-blue-600 md:text-xl">Las clases particulares cuentan con ciertos beneficios, tales como: </p>
        <ul className="my-2 font-bold font-lato text-lg px-2 md:text-xl">
          <li className="my-3">■ Clases centradas únicamente en tus necesidades, con contenidos y ejercicios personalizados</li>
          <li className="my-3">■ Aprender a tu propio ritmo </li>
          <li className="my-3">■ Poder resolver tus dudas inmediatamente</li>
        </ul>
        </div>
        
      </div>
      <div className="flex flex-col items-center bg-base text-white py-5 md:py-16">
        <p>Mándanos un Whatsapp al:</p>
        <p className="flex items-center"><BsWhatsapp/><a href="https://wa.me/525568742266?text=Me%20interesa%20saber%20más%20de%20las%20clases" className="underline">+52 55 6874 2266</a></p>
      </div>
    </div>
  );
}
