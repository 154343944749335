import { Link } from "react-router-dom";
import Navbar from "../Navbar";

export default function FrontPage({ image, title, subTitle }) {
  let links = [
    { link: "/", name: "Home" },
    { link: `${title === 'CONTACTAR'? '/EnglishCourses': '/Contact'}`, name: `${title === 'CONTACTAR'? 'Cursos de inglés': 'Contactar'}` },
  ];

  return (
    <div className="relative">
      <img className="-z-10 absolute brightness-50 w-full" src={image} alt={title} />
      <Navbar>
        {links.map((item, index) => {
          return (
            <Link key={index} to={item.link}>
              <span className="m-3 p-2 rounded-md md:text-white font-lato md:text-xl">
                {item.name}
              </span>
            </Link>
          );
        })}
      </Navbar>
      <div className="flex flex-col items-center ">
        <h1 className="text-amber-600 text-5xl font-oswald font-bold text-center md:text-6xl">
          {title}
        </h1>
        <p className="font-lato text-white text-lg text-center my-2 md:text-2xl">
          {subTitle}
        </p>
      </div>
    </div>
  );
}
