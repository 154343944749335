import Navbar from "../../components/Navbar";
import {Link} from 'react-router-dom'
import CardClass from "../../components/CardClass";
import BusinessEnglish from '../../img/BusinessEnglish/BusinessEnglish.webp'
import EnglishOnline from '../../img/EnglishOnline/EnglishOnline.webp'
import FaceToFace from '../../img/FaceToFace/FaceToFace.webp'
import BannerEnglishCourses from "../../components/BannerEnglishCourses";
import PointingMan from "../../img/PointingMan/MenPointingLeft.webp"
import Footer from "../../components/Footer";

export const EnglishCourses = ()=> {
  let links = [
    { link: "/", name: "Home" },
    { link: "/Contact", name: "Contactar" },
  ];

  return (
    <>
    <section className="bg-base">
      <Navbar>
        {links.map((item, index) => {
          return (
            <Link key={index} to={item.link}>
              <span className="m-3 p-2 rounded-md md:text-white font-lato md:text-xl">
                {item.name}
              </span>
            </Link>
          );
        })}
      </Navbar>
    </section>
    <section  className="bg-base md:flex md:justify-center md:py-3">
    <CardClass
          kindOfClass="Business English"
          image={BusinessEnglish}
          textColor={"normal"}
        />
        <CardClass
          kindOfClass="English Online"
          image={EnglishOnline}
          textColor={"normal"}
        />
        <CardClass
          kindOfClass="Face To Face"
          image={FaceToFace}
          textColor={"normal"}
        />
    </section>
    <BannerEnglishCourses image={PointingMan}/>
    <Footer/>
    </>
    
  );
}
