import {Link} from 'react-router-dom'
import Navbar from "../components/Navbar";
import CardClass from "../components/CardClass";
import { AiOutlinePhone } from "react-icons/ai";
import { useState } from "react";
import Faq from "../components/FAQ";
import Carousel from "../components/Carousel";
import BusinessEnglish from '../img/BusinessEnglish/BusinessEnglish.webp'
import EnglishOnline from '../img/EnglishOnline/EnglishOnline.webp'
import FaceToFace from '../img/FaceToFace/FaceToFace.webp'
import Portada from '../img/Places/PortadaElc.webp'


export const Home = ()=> {
  const [isOpened, setIsOpened] = useState(false);
  const open = (e) => {
    setIsOpened(!isOpened);
  }

  let links = [
    { link: "/EnglishCourses", name: "Cursos de inglés" },
    { link: "/Contact", name: "Contactar" },
  ];
  return (
    <div className="p-0 select-none">

      <div >
        <img
          className="-z-10 absolute brightness-50 w-full"
          src= {Portada}
          alt="portada de elc"
        />
        <Navbar>
          {links.map((item, index) => {
            return (
              <Link key={index} to={item.link} about={item.name}>
                <span className="m-3 p-2 py-4 rounded-md md:text-white font-lato md:text-xl">
                  {item.name}
                </span>
              </Link>
            );
          })}
        </Navbar>
        <div className="flex flex-col items-center text-white mx-0">
          <h1 className="font-oswald text-2xl md:h-40 md:text-8xl">
            <strong>ELC</strong>
          </h1>
          <p className="text-lg mb-2 md:text-5xl md:mb-11">
            Executive Language Center
          </p>
        </div>
      </div>

      <div className="bg-base flex justify-center flex-col items-center md:flex-row md:justify-around ">
        <h2 className=" text-white font-oswald w-full flex justify-center items-center  text-lg md:text-3xl md:w-2/5">
          <strong>ONLINE ENGLISH LESSONS</strong>
        </h2>
        <div  className="text-base m-6 w-3/4 p-2 border rounded-md bg-white flex items-center justify-center md:w-1/5">
          <AiOutlinePhone />
         <a  href="https://wa.me/14155392434?text=Me%20interesa%20saber%20más%20de%20las%20clases">+1 415 539 2434</a> 
        </div>
      </div>

      <div className="bg-white">
        <section className="flex flex-col bg-fondo items-center justify-center md:flex-row">
          <span className="text-center my-2 md:text-2xl md:mr-4">
            <strong>¡Por tiempo limitado!</strong> Tu primer curso a un{" "}
            <strong>PRECIO ESPECIAL</strong>
          </span>
          <div className=" text-white border rounded-md m-3 p-3 bg-base md:text-xl">
            ¡Aprende desde HOY!
          </div>
        </section>
        <section className="flex flex-col items-center my-3 mx-1 bg-white md:m-0">
          <h3 className="text-red-600 font-lato md:text-xl md:w-full md:pl-32">
            Cursos Online
          </h3>
          <p className="font-oswald font-bold text-lg text-center my-3 md:text-xl">
            Clases de Inglés con profesores nativos Americanos, con más de 10
            años de experiencia.
          </p>
          <p className="font-lato text-center text-lg md:text-xl md:mb-3">
            Experiencia impartiendo clases en distintos lugares de
            Latinoamérica, tales como:
            <strong>
              Argentina, Chile, Colombia, Ecuador, México, Uruguay y más.
            </strong>
          </p>
          <ul className="font-lato flex flex-col text-lg md:items-start md:py-4 md:text-xl text-center">
            <li>
              ✔
              Precios accesibles.
            </li>
            <li>
              ✔
              Horarios flexibles.
            </li>
            <li>
              ✔
              Paquetes personalizables y adaptados a tus necesidades, objetivos
              y metas.
            </li>
            <li>
              ✔
              Promociones por fechas especiales!
            </li>
          </ul>
        </section>
      </div>

      <div className="bg-white md:flex md:justify-center md:py-8 md:m-0">
        <CardClass
          kindOfClass="Business English"
          image={BusinessEnglish}
          textColor={"base"}
        />
        <CardClass
          kindOfClass="English Online"
          image={EnglishOnline}
          textColor={"base"}
        />
        <CardClass
          kindOfClass="Face To Face"
          image={FaceToFace}
          textColor={"base"}
        />
      </div>

        <h2 className="font-oswald text-center m-2 bg-white md:m-0 md:text-2xl">
          ¡MÁS DE 10 AÑOS IMPARTIENDO CLASES PARTICULARES Y A EMPRESAS!
        </h2>
        <span className="text-base font-lato text-center m-2 flex md:m-0 md:bg-white md:text-2xl md:text-right md:flex md:justify-center md:py-4">
          Experiencia en Business English y Clases Online profesionales, con las
          mejores plataformas
        </span>
        <div className="md:flex  md:justify-center">
          <div className=" md:w-1/2">
            <Carousel />
          </div>
        </div>
        
      
      <ul className="flex flex-col items-center text-base text-blue-600 md:text-xl md:flex-row md:justify-around md:py-6">
        <li className="my-2">Profesores Nativos</li>
        <li className="my-2">Horario de tu conveniencia</li>
        <li className="my-2">Ebooks y más herramientas</li>
        <li className="my-2">Preparación para IELTS, TOEFL, Cambridge</li>
      </ul>
      <h3 className="text-center my-3 md:text-xl">¿CÓMO CONTACTAR AL PROFESOR?</h3>
      <Faq isOpened={isOpened} open={open} />
    </div>
  );
}
