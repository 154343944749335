import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Argentina from '../../img/Places/Argentina.webp'
import Brasil from '../../img/Places/Brasil.webp'
import Chile from '../../img/Places/Chile.webp'
import CDMX from '../../img/Places/CiudadDeMexico.webp'
import Colombia from '../../img/Places/Colombia.webp'
import Guadalajara from '../../img/Places/Guadalajara.webp'
import Monterrey from '../../img/Places/Monterrey.webp'


const images = [
  {
    img: Argentina, 
    title: 'Argentina'
  },
  {
    img: Brasil,
    title: 'Brasil'
  },
  {
    img: Chile,
    title: 'Chile'
  },
  {
    img: CDMX,
    title: 'Ciudad de México'
  },
  {
    img: Colombia,
    title: 'Colombia'
  },
  {
    img:Guadalajara,
    title: 'Guadalajara'
  },
  {
    img: Monterrey,
    title: 'Monterrey'
  }

];

const Slideshow = () => {
  return (
    <>
    <div className="slide-container md:hidden">
      <Zoom scale={0.8}>
        {images.map((each, index) => (
          <div key={each.title} className="flex flex-col items-center">
            <img
            key={index}
            style={{
              borderRadius: "2rem",
              display: "flex",
              alignItems: "center",
               padding: "1rem",
              height: "20rem",
              width: "100%",
              paddingTop: "1rem",
            }}
            src={each.img}
            alt={each.title}
          />
          <p className="font-oswald font-bold">{each.title}</p>
          </div>
        ))}
      </Zoom>
    </div>

    <div className="hidden md:visible md:flex md:px-20">
      <div className="w-full">
        <Zoom scale={0.5}>
      {images.map((each, index) => (
          <div key={each.title} className="flex flex-col items-center">
            <img
            key={index}
            style={{
              borderRadius: "2rem",
              display: "flex",
              alignItems: "center",
              height: "600px",
              paddingTop: "1rem",
            }}
            src={each.img}
            alt={each.title}
          />
          <p className="font-oswald font-bold text-2xl">{each.title}</p>
          </div>
        ))}
      </Zoom>
      </div>

    </div>
    </>
  );
};

export default Slideshow;
