import {BsWhatsapp} from "react-icons/bs"
import { Link } from "react-router-dom"

export default function Footer(){
    return (
        <footer className="flex flex-col text-center bg-base text-white py-3 font-lato md:text-xl">
        <Link to={'/Contact'} about='Ir a pagina de contacto'><span className="underline">Para más información</span></Link>
        <p className="underline"></p>
        <p>Mandanos un Whatsapp al:</p>
        <p className="flex justify-center items-center"><BsWhatsapp/><a href="https://wa.me/525568742266?text=Me%20interesa%20saber%20más%20de%20las%20clases" className="underline">+52 55 6874 2266</a></p>
    </footer>
    )
}