import { Link } from "react-router-dom";

export default function CardClass({ kindOfClass, image, textColor}) {

  const colorMap ={
    "base":"text-base",
    "normal":"text-white"
  }
  const englishCourseMap = {
    'Business English':'BusinessEnglish',
    'English Online':'EnglishOnline',
    'Face To Face':'FaceToFace'
  }
  
  return (
        <div className="flex justify-center flex-col items-center mx-2">
      <img src={image} alt={kindOfClass} width={400} height={300} />
      <p className={`font-oswald text-lg my-2 ${colorMap[textColor]}`}>{kindOfClass}</p>
      <Link to={`/EnglishCourses/${englishCourseMap[kindOfClass]}`} about={kindOfClass}>
        {/* <a> */}
           <button className={`my-3 border rounded-md p-3 font-lato shadow-md ${colorMap[textColor]}`}>
        Más Información
      </button>
        {/* </a> */}
      </Link>
    </div>
  );
}
