import FrontPage from "../../components/FrontPage";
import CarouselFaceToFace from "../../components/CarouselFaceToFace";
import {BsWhatsapp} from "react-icons/bs"
import Girl from '../../img/FaceToFace/Girl&Girl.webp'
import Portada from '../../img/FaceToFace/FaceToFacePortada.webp'

export const FaceToFace = ()=> {
  return (
    <div className="select-none">
      <div className="overflow-hidden">
        <FrontPage
        image={Portada}
        title={"Face To Face"}
        subTitle={"Clases más divertidas, cara a cara con el profesor"}
      />
      </div>
      
      <div className="bg-gray-100 p-2 ">
        <p className="text-center font-lato text-lg text-blue-600 md:text-xl">
          ¿Cuál es la ventaja?
        </p>
        <p className="text-center font-lato text-lg">
          Clases Face to Face te permiten un aprendizaje más divertido y
          avanzado. Te permite desarrollar habilidades sociales en el idioma,
          tales como: confianza, mejor dialecto, entre otros.{" "}
        </p>
      </div>
      <div className="bg-white md:flex">
        <div className="md:w-1/2 md:my-4">
          <CarouselFaceToFace />
        </div>
        <div className="px-2 md:flex md:flex-col md:justify-around">
          <h2 className="text-center font-lato text-lg text-blue-600">
            ¿Por qué tomar un curso Face to Face?
          </h2>
          <p className="text-center font-lato text-lg my-3">
            Desde inicios de la pandemia hemos estado adquiriendo{" "}
            <strong>problemas sociales</strong> que se fueron desarrollando a lo
            largo de esta, además de haber perdido{" "}
            <strong>habilidades sociales</strong> con las que contábamos.{" "}
          </p>
          <p className="text-center font-lato text-lg">
            Nosotros te ayudamos a mejorar tus habilidades en{" "}
            <strong>Public speaking,</strong> perder el miedo a hablar en
            público es mucho más sencillo en una clase impartida
            presencialmente. Con este modelo podrás practicar tu conversación y{" "}
            <strong>desarrollar una confianza mucho más rápida.</strong>
          </p>
        </div>
      </div>
      <div className="bg-base">
        <h2 className="text-xl font-oswald text-center text-white py-3">
          ¡LO MEJOR, SOLO PARA TI!
        </h2>
      </div>
      <div className="md:flex md:bg-white md:flex-row-reverse md:justify-around ">
        <div className="p-3 ">
          <h2 className="text-center text-xl  font-lato text-base ">Si lo que buscas es:</h2>
          <ul className="text-lg font-lato md:my-4">
            <li>✔ Tener clases divertidas y entretenidas</li>
            <li>✔ Poner a prueba tus habilidades sociales al momento de hablar Inglés</li>
            <li>✔ Desarrollar nuevas habilidades</li>
            <li>✔ Conversaciones cara a cara</li>
            <li>✔ Sentirte seguro de ti mismo y desarrollar confianza</li>
          </ul>
        </div>
        <div className="p-2 ">
          <img src={Girl} alt=""  className="shadow-md rounded-md"/>  
        </div>
      </div>
      <h2 className="font-oswald text-center text-xl text-amber-600 font-bold md:text-2xl bg-white md:py-4">¡Opta por este modo!</h2>
      <div className="text-center bg-base text-white py-6 md:py-14">
        <p>Mándanos un Whatsapp al: </p>
        <p className="flex items-center justify-center"><BsWhatsapp/><a href="https://wa.me/525568742266?text=Me%20interesa%20saber%20más%20de%20las%20clases" className="underline">+52 55 6874 2266</a></p>
      </div>
    </div>
  );
}
