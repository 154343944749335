import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Girl from '../../img/BusinessEnglish/Carousel/GirlStaring.webp'
import Group from '../../img/BusinessEnglish/Carousel/GroupBusiness.webp'
import Woman from '../../img/BusinessEnglish/Carousel/WomanWithGroup.webp'

const images = [
  {
    img: Girl, 
    title: 'Girl studying'
  },
  {
    img: Group,
    title: 'Group studying'
  },
  {
    img: Woman,
    title: 'Woman studying with group'
  }

];

const Slideshow = () => {
  return (
    <>
    <div className="slide-container md:hidden">
      <Zoom scale={0.8}>
        {images.map((each, index) => (
          <div key={each.title} className="flex flex-col items-center">
            <img
            key={index}
            style={{
              borderRadius: "2rem",
              display: "flex",
              alignItems: "center",
              padding: "1rem",
              paddingTop: "1rem",
            }}
            src={each.img}
            alt={each.title}
          />
          </div>
        ))}
      </Zoom>
    </div>

    <div className="hidden md:visible md:flex md:px-20">
      <div className="w-full">
        <Zoom scale={0.5}>
      {images.map((each, index) => (
          <div key={each.title} className="flex flex-col items-center">
            <img
            key={index}
            style={{
              borderRadius: "2rem",
              display: "flex",
              alignItems: "center",
              paddingTop: "1rem",
            }}
            src={each.img}
            alt={each.title}
          />
          </div>
        ))}
      </Zoom>
      </div>

    </div>
    </>
  );
};

export default Slideshow;