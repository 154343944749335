import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Couple from '../../img/FaceToFace/Carousel/Couple.webp'
import CoupleMan from '../../img/FaceToFace/Carousel/CoupleMan&Woman.webp'
import CoupleWoman from '../../img/FaceToFace/Carousel/CoupleWomen.webp'


const images = [
  {
    img: Couple, 
    title: 'Couple'
  },
  {
    img: CoupleMan,
    title: 'Couple Man & Woman'
  },
  {
    img: CoupleWoman,
    title: 'Couple Women'
  }

];

const Slideshow = () => {
  return (
    <>
    <div className="slide-container md:hidden">
      <Zoom scale={0.8}>
        {images.map((each, index) => (
          <div key={each.title} className="flex flex-col items-center">
            <img
            key={index}
            style={{
              borderRadius: "2rem",
              display: "flex",
              alignItems: "center",
              padding: "1rem",
              paddingTop: "1rem",
            }}
            src={each.img}
            alt={each.title}
          />
          </div>
        ))}
      </Zoom>
    </div>

    <div className="hidden md:visible md:flex md:px-20">
      <div className="w-full">
        <Zoom scale={0.5}>
      {images.map((each, index) => (
          <div key={each.title} className="flex flex-col items-center">
            <img
            key={index}
            style={{
              borderRadius: "2rem",
              display: "flex",
              alignItems: "center",
              paddingTop: "1rem",
            }}
            src={each.img}
            alt={each.title}
          />
          </div>
        ))}
      </Zoom>
      </div>

    </div>
    </>
  );
};

export default Slideshow;