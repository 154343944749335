import {ImMenu} from "react-icons/im"
import {AiOutlineClose} from "react-icons/ai"
import { useState } from "react";
import LogoElcW from '../../img/Logos/LogoElcW.svg'

export default function NavbarComponent({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (

    <nav className="select-none flex flex-col md:flex-row">
      <div className="flex md:hidden">
        <div className="w-1/4 md:pl-6"><img src={LogoElcW} alt="Logo Elc" className="md:h-28" /></div>
        <div className=" w-3/4 flex justify-end items-center pr-6 md:hidden">{!isMenuOpen? <ImMenu className="text-white" onClick={()=>setIsMenuOpen(!isMenuOpen)}/>: <AiOutlineClose className="text-white" onClick={()=>setIsMenuOpen(!isMenuOpen)}/>}</div>
      </div>
      
     {isMenuOpen && <div className=" fixed z-20 top-14 w-full flex justify-end  md:hidden">
        <ul className="flex flex-col w-3/4 items-end rounded-bl-lg pr-3 bg-white h-20 justify-around">{children}</ul>
      </div>}

      <div className="hidden md:flex w-1/4 md:pl-6"><img src={LogoElcW} alt="Logo Elc" className="md:h-28" /></div>
      <ul className="hidden md:flex md:w-3/4 items-end md:justify-end pr-3 md:items-center">{children}</ul>
    </nav>
  );
}
